<swiper-container class="swiper-reviews" pagination="true">
  @if(reviews) {
    @for(review of (reviews | async); track review.uuid){
      <ng-container *ngTemplateOutlet="reviewsTemplate; context: { $implicit: review }" ></ng-container>
    }
  }
    @for(image of projectImagesWithColors; track image) {
      <ng-container *ngTemplateOutlet="imagesTemplate; context: { $implicit: image }" ></ng-container>
    }
</swiper-container>

@if(((projectImages?.length ?? 0) > 2) || ((reviews | async)?.length ?? 0) > 2) {
  <div class="swiper-actions">
    <button class="button-icon prev" (click)="prevSlide()">
      <mat-icon class="rotate small" svgIcon="arrow-right"></mat-icon>
    </button>
    <button class="button-icon next" (click)="nextSlide()">
      <mat-icon class="small" svgIcon="arrow-right"></mat-icon>
    </button>
  </div>
}

<ng-template #reviewsTemplate let-review>
  <swiper-slide>
    <div class="slider-border border-custom middle">
      <div class="slider-wrapper">
        <div class="review-logo">
          <div
            class="logo"
            [style.background-image]="'url(' + review.logoUrl + ')'"
          ></div>
          <h3>
            {{ review.name }}
          </h3>
        </div>
        <p class="l normal">
          {{ review.text }}
        </p>
      </div>
    </div>
  </swiper-slide>
</ng-template>

<ng-template #imagesTemplate let-image>
  <swiper-slide>
    <div class="slider-image-border" [style.background-color]="image.color">
      <div
        class="slider-image project"
        [style.background-image]="'url('+ url + image.img + ')'"
      ></div>
    </div>
  </swiper-slide>
</ng-template>
