import { Observable } from 'rxjs';
import { AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { Reviews } from '@interfaces/reviews.interface';
import { SwiperContainer } from 'swiper/element';
import { environment } from 'src/environments/environment';
import { BACKGROUND_COLORS } from '@const/backgroud-colors.const';
import { ImagesColor } from '@interfaces/images.interface';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [MatIconModule, AsyncPipe, NgTemplateOutlet],
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SliderComponent implements AfterViewInit, OnInit {
  @Input() reviews: Observable<Reviews[]> | null = null;
  @Input() projectImages: string[] | null = null;
  @Input() slidesCount: number | null = null;

  public url: string = environment.staticUrl;
  public swiperEl: SwiperContainer | null = null;
  public projectImagesWithColors: ImagesColor[] = [];

  constructor() {}

  ngOnInit() {
    this.getColorImage();
  }

  ngAfterViewInit(): void {
    this.swiperEl = document.querySelector('.swiper-reviews');
    if (this.swiperEl) {
      this.swiperEl!.addEventListener('swiperInit', () => {});
      this.swiperEl!.swiper.on('slideChange', () => {});
    }
    const swiperParams = {
      slidesPerView: this.projectImages && window.innerWidth > 1000 ? 2 : 1,
      spaceBetween: 40,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },
    };

    Object.assign(this.swiperEl!, swiperParams);
    (this.swiperEl as SwiperContainer).initialize();
  }

  public getColorImage() {
    if (this.projectImages) {
      this.projectImagesWithColors = this.projectImages!.map(
        (image, index) => ({
          img: image,
          color:
            index < BACKGROUND_COLORS.length
              ? BACKGROUND_COLORS[index]
              : BACKGROUND_COLORS[
                  index -
                    Math.floor(index / BACKGROUND_COLORS.length) *
                      BACKGROUND_COLORS.length
                ],
        })
      );
    }
  }

  public prevSlide() {
    this.swiperEl!.swiper.slidePrev();
  }

  public nextSlide() {
    this.swiperEl!.swiper.slideNext();
  }
}
